
// Content styles
.block-text,
.biography {
	line-height: 1.7;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding: 0 0 $paragraph-margin;
			border-bottom: $border;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h4;
			
			@media #{$mobileXL} {
				font-size: $h3;
			}
		}
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
	}

	blockquote {
		padding: 0;
		margin: 0;
		margin-bottom: $block-margin / 2;
		padding-bottom: $block-padding;
		border-bottom: $border;
		font-family: $font-family-title;
		font-size: $h4;

		cite {
			font-style: normal;
			font-family: $font-family-body;
			font-size: $h6 - 0.2;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

