
//==========  Fonts  ==========//

// call our font families and fallbacks
// variables for title, body and sub paragraphs are called in /core/_typography.scss

$font-family-title: 'Cormorant', 'Helvetica Neue', 'Arial', sans-serif;	// for heading tags
$font-family-body:  "Lato", sans-serif;									// for body text
$font-family-sub:  $font-family-title;				// for sub and intro text						


//==========  Font weights  ==========//

// normal and bold are used in our modules as defaults
// set your most commonly used weights to use these if possible

$font-normal: 	400;	// Normal 400
$font-bold: 	700;	// Bold 700


//==========  Font styles  ==========//

// set font-style in variable so its easy to update
$font-italic: italic;


//==========  Font sizes  ==========//

// set sizes of heading tags
// we can then call these on any element to duplicate the tag

$h1: 2.8rem;
$h2: 2.2rem;
$h3: 1.7rem;
$h4: 1.5rem;
$h5: 1.3rem;
$h6: 1.1rem;

// set the default body font size
$font-size-body: 1.1rem;


//==========  Font margins  ==========//

// bottom margin for headings and paragraphs
// use to control the flow of content on the page
// lists in content will use our paragraph variable

$heading-margin: 2rem;
$paragraph-margin: 2rem;


